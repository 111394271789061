
.top-wrapper {
  min-height: 100vh;
}

.stored-config {
  display: flex;
}

.stored-config-left {
  width: 40%;

  .stored-config-group {
    padding: 0.5em 0.3em 0.5em 0;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.stored-config-right {
  padding: 0 0 0 0.3em;
  width: 60%;
}

.stored-config-title {
  display: block;
  padding:  0 0 0.3em;
  font-size: 70%;
  font-weight: 600;
}

.stored-config-value {
  font-size: 70%;
}

.stored-config-values-wrapper {
  display: flex;
}

.stored-config-icon-wrapper {
  display: block;
  width:   40%;
  text-align: center;
}

.stored-config-values {
  width:     60%;
  font-size: 70%;

  strong {
    font-weight: 600;
  }
}


@import "styling/vars.scss";
@import "styling/reset.scss";
@import "styling/util.scss";
@import "styling/forms.scss";
@import "styling/link.scss";
@import "styling/tabs.scss";
@import "styling/mode-dark.scss";
@import "styling/mode-light.scss";
@import "styling/mode-edit.scss";
@import "styling/mode-view.scss";
@import "styling/debug.scss";

select,
option,
input,
textarea {
  background: none;
  border:     1px solid;
}

* { box-sizing: border-box; }

html,
body,
h1, h2, h3,
h4, h5, h6 {
  margin:  0;
  padding: 0;

  font: 1.25rem $primary-font;
  font-weight: 300;
}

strong, b {
  font-weight: 600;
}

em, i {
  font-style: italic
}

body {
  height: 100%;
}

.container {
  // is this dumb?
  min-width: 45%;
  max-width: 55%;

  min-height:  100vh;
  margin:  0 auto;
  padding: 0.5em;
  border-left:  3px solid;
  border-right: 3px solid;
}

.privacy-policy {
  h3 {
    font-size: 1.6rem;
    margin:    0 0 1em 0;
  }

  $size: 1.05rem;

  p {
    font-size: $size;
    margin:    0 0 1em 0;
  }

  ul {
    padding: 0 0 0 1em;
    margin:  0 0 1.5em 0;
    list-style-type: circle;
  }

  li {
    font-size: $size;
    margin:    0 0 0.5em 0;
  }
}

footer {
  margin: 0.5em -0.5em -0.5em -0.5em;

  .privacy-policy {
    background: #333;
    color:      #efefef;

    margin:  0.5em;
    padding: 1em 0.5em;


    .btn-in-privacy-policy {
      padding: 0.5em 0;
      font-size: 1.2rem;
      color: #efefef;
      background: rgba(255, 255, 255, 0.2);

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .footer-sections {
    display: flex;
  }

  .footer-section {
    font-size: 0.85rem;
    width:     50%;
    padding:   0.6em;

    .btn-privacy-policy {
      font-size: 0.8rem;
    }
  }

  .section-right {
    text-align: right;
  }
}


@import "color-utils";

.mode--dark {
  a {
    color: rgb(255, 0, 255);

    &:hover {
      color: #fff;
    }
  }

  .container {
    background: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.2);
  }

  &body,
  footer,
  div,
  .btn,
  .form-control {
    color: rgba(255, 255, 255, 0.8);
  }

  footer {
    background: rgba(255, 255, 255, 0.1);
  }

  .btn,
  .form-control {
    color: #fff;

    option {
      background: #000;
    }

    @include input-coloring(240, 240, 240, 0.15, 0.25);
  }

  .btn-use-config {
    @include input-coloring(220, 120, 30, 0.3, 0.6);
  }

  .btn-delete-config {
    @include input-coloring(255, 0, 0, 0.3, 0.6);
  }

  .btn-toggle-big {
    @include input-coloring(0, 255, 0, 0.2, 0.4);
  }

  .btn-set-default {
    @include input-coloring(100, 50, 0, 0.2, 0.4);
  }

  .btn-color-random {
    @include input-coloring(200, 130, 30, 0.2, 0.4);
  }

  .footer-section .btn-privacy-policy {
    @include input-coloring(255, 255, 255, 0.2, 0.4);
  }

  .stored-config-left .stored-config-group {
    border-color: rgba(255, 255, 255, 0.6);
  }
}

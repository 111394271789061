
a {
  color: rgba(blue, 40%);

  &:hover {
    color: rgba(blue, 60%);
  }

  &:active,
  &:visited,
  &:focus {
    color: rgba(purple, 60%)
  }
}

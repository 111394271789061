
@import "color-utils";

.mode--light {
  a {
    color: rgb(40, 0, 40);

    &:hover {
      color: #000;
    }
  }

  .container {
    background: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.2);
  }

  &body,
  footer,
  div {
    color: rgba(0, 0, 0, 0.8);
  }

  footer {
    background: rgba(0, 0, 0, 0.1);
  }

  .btn,
  .form-control {
    color: #000;

    option {
      background: #fff;
    }

    @include input-coloring(20, 20, 20, 0.15, 0.25);
  }

  .btn-use-config {
    @include input-coloring(220, 120, 30, 0.6, 0.3);
  }

  .btn-delete-config {
    @include input-coloring(255, 0, 0, 0.6, 0.3);
  }

  .btn-toggle-big {
    @include input-coloring(0, 255, 0, 0.4, 0.2);
  }

  .btn-set-default {
    @include input-coloring(100, 50, 0, 0.4, 0.2);
  }

  .btn-color-random {
    @include input-coloring(200, 130, 30, 0.4, 0.2);
  }

  .footer-section .btn-privacy-policy {
    @include input-coloring(255, 255, 255, 0.2, 0.4);
  }

  .stored-config-left .stored-config-group {
    border-color: rgba(0, 0, 0, 0.6);
  }
}


.debug-area {
  display: flex;
  background: darken(orange, 25%);

  .large-img,
  .small-img {
    display:   flex;
    flex-wrap: wrap;
    align-self: flex-start;
  }

  $small: 60px;
  $large: 90px;

  .small-img {
    width: $small * 4.5;
  }

  .large-img {
    width:  $large * 6;
    border: 1px solid pink;
  }

  img {
    display: block;
    cursor:  pointer;
    margin:  0.2em;
    padding: 0.2em;
    border:  1px solid pink;
  }

  canvas {
    display: none;
  }
}


.mode--view {
  .container {
    width:  100%;
    max-width: 100%;
    border: none;
    background: none;
  }

  text-align: center;

  .tab-edit-master {
    display: none;
  }

  .container {
    position: relative;
  }

  .tab-view-title,
  .tab-view-body,
  .tab-view-notes {
    padding:     0;
    margin:      0;
    line-height: 1;
  }

  .tab-view-title {
    margin: 0 0 15px 0;
  }

  .tab-view-notes {
    padding:    0 0.3em;
    text-align: left;
  }

  .toggle-view-wrapper {
    position: absolute;
    bottom:   3.5em;
    left:     0.5em;
    right:    0.5em;
  }

  .privacy-policy {
    margin-bottom: 5em;
  }

  .privacy-policy,
  .section-left {
    text-align: left;
  }
}


.form-group {
  margin: 0 0 0.6em 0;
}

.form-label {
  display:     block;
  padding:     0 0 0.3em;
  font-size:   1.5rem;
  font-weight: 600;
}

.form-control {
  padding:       0.2em 0.4em;
  border-radius: 0.23em;
  font-size:     1.25rem;
  display:       block;
  min-height:    2em;

  width: 100%;
}

textarea {
  resize: vertical;
}

select,
input[type=color] {
  cursor: pointer;
}

.form-control[type=color] {
  cursor:  pointer;
  padding: 0;
  margin:  0;
  background: rgba(255, 255, 255, 0.4);
}

.form-group-horizontal-3 {
  display: flex;

  .form-group {
    width: 33.3333%;
  }

  .form-group:nth-child(2) {
    padding: 0 0.2em;
  }
}

.form-input-button-group {
  display: flex;

  .form-control {
    width: 100%;
    margin: 0 0.3em 0 0;
  }

  .btn {
    width: 20%;
  }
}

.btn {
  border:        1px solid;
  border-radius: 0.23em;
  background:    rgba(255, 255, 255, 0.3);

  font-size:  70%;

  &:hover {
    cursor: pointer;
  }
}

.stored-config-buttons {
  padding: 0.5em 0;
  display: flex;

  .btn:first-child {
    margin-right: 0.5em;
  }

  .btn {
    padding: 0.5em 0;
  }

  .btn-delete-config {
    width: 25%;
  }

  .btn-use-config {
    width: 75%;
  }
}

.btn-block {
  display: block;
  width:   100%;
  text-align: center;
}

.btn-toggle-big,
.btn-set-default {
  font-size: 1.3rem;
  padding:   0.5em 0;
}

.btn-svg img {
  margin-top: 0.3em;
  width: 2em;
  height: 2em;
}
